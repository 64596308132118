.Governing-body{
    margin: 4rem auto;
    img{
        width:150px;
        height:150px;
        border-radius: 20rem;
    }
}

.greenBox{
    display: flex;
}

.greenBox{
    border-radius: 10rem;
    background-color:#E5FFF3;
    height:6.5rem;
    width:39vw;
    h2{
        font-size: 1.7rem;
        padding: 0.8rem 1.5rem 0 1.6rem; 
    }
    h3{
        font-size: 1.2rem;
        padding-left: 1.6rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }
    margin: 6rem;
    align-items: center;
}


@media only screen and (max-width:768px){
    .Governing-body{
        margin: auto;
        img{
            width:80px;
            height:80px;
            border-radius: 20rem;
        }
    }
    .greenBox{
        border-radius: 10rem;
        height:3rem;
        width:70vw;
        h2{
            font-size: 0.8rem;
            padding: 0rem 1rem 0rem 1rem; 
        }
        h3{
            font-size: 0.6rem;
            padding: 0 1rem;
        }
        margin: 3rem 4rem;
    }
}

@media only screen and (min-width:576px) and (max-width:768px) {
    .Governing-body{
        img{
            width:90px;
            height:90px;
        }
    }
    .greenBox{
        border-radius: 10rem;
        height:3rem;
        width:40vw;
        h2{
            font-size: 0.8rem;
            padding: 0rem 1rem 0rem 1rem; 
        }
        h3{
            font-size: 0.6rem;
            padding: 0 1rem;
        }
        margin:4rem 3rem ;
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .Governing-body{
        img{
            width:100px;
            height:100px;
        }
    }
    .greenBox{
        border-radius: 10rem;
        height:4rem;
        width:40vw;
        h2{
            font-size: 1.2rem;
            padding: 0rem 1rem 0rem 1rem; 
        }
        h3{
            font-size: 0.8rem;
            padding: 0 1rem;
        }
        margin:4rem 3rem ;
    }
}

