.adm-box {
  display: flex;
  margin: 2rem auto;
  width: 80%;
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  ol {
    padding-left: 1rem;
  }
}

.adm-box2 {
  display: flex;
  margin: 2rem auto;
  width: 80%;
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  ol {
    padding-left: 1rem;
  }
}

.procedure {
  background-color: #01471B;
  color: white;
  padding: 2rem 2rem;
  border-radius: 1rem 0 0 1rem;
  text-align: center;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-family: 'Calibre';
}

.procedure2 {
  background-color: #01471B;
  color: white;
  padding: 2rem 2rem;
  border-radius: 0 1rem 1rem 0;
  text-align: center;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-family: 'Calibre';
}

.procedure-con {
  width: 80%;
  padding: 1rem 4rem;
  text-align: justify;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-family: 'Calibre';
}

@media only screen and (max-width: 990px) {
  .awardSection{
      flex-direction: column;
      margin: 0;
      justify-content: center;
      align-items: center;
      text-align: center;
  }

  .adm-box{
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .procedure{
          width: 100%;
          border-radius: 0;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
      }
      .procedure-con{
          padding: 1rem 0;
          text-align: center;
      }
  }

  .adm-box2{
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      .procedure2{
          width: 100%;
          border-radius: 0;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
      }
      .procedure-con{
          padding: 1rem 0;
          text-align: center;
      }
  }
}