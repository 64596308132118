.numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0fff8 ;
  margin: 1.5rem 0rem;
  color: #01471B;
  ;
  font-style: normal;
  font-weight: normal;
  padding: 1.5rem;
  width: 100%;
}

.counter {
  color: #01471B;

  span {
    font-size: 1.75rem;
  }

  p {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: "990px"){
  .counter{
    span{
      font-size: 1.2rem;
    }
  }

  .numbers{
   padding: 0.5rem;
  }
}
