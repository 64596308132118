.aboutHead {
    font-family: "Calibri";
    color: #01471B;
    h1 {
        margin: 2rem auto;
        text-decoration: underline;
        text-align: center;
    }
    tr {
        border: 1px solid black;
        font-size: 1.2rem;
    }
    th {
        background: #badfce;
    }
    table {
        width: 80vw;
        margin: 4rem auto;
        text-align: center;
        border: 1px solid #01471B;
        overflow: scroll;
    }
    .button-74 {
        background-color: #badfce;
        border: 1px solid #422800;
        // border-radius: 20px;
        box-shadow: #422800 4px 4px 0 0;
        color: #422800;
        cursor: pointer;
        display: inline-block;
        font-weight: 500;
        font-size: 1.3rem;
        padding: 0 28px;
        line-height: 45px;
        text-align: center;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }
    .button-74:hover {
        background-color: #fff;
    }
    .button-74:active {
        box-shadow: #422800 2px 2px 0 0;
        transform: translate(2px, 2px);
    }
    @media (min-width: 768px) {
        .button-74 {
            min-width: 120px;
            padding: 0 18px;
            
        }
    }

    @media (max-width: 676px) {
        .button-74 {
            min-width: 70px;
            padding: 0 10px;
            font-size: 1.2rem;
        }
    }

    @media (max-width: 400px) {
        .button-74 {
            min-width: 50px;
            padding: 0 5px;
            font-size: 1rem;
        }

        tr{
            font-size: 1rem;
        }
    }

    @media (max-width: 300px) {
        .button-74 {
            min-width: 30px;
            padding: 0 1px;
            font-size: 0.8rem;
        }

        tr{
            font-size: 0.8rem;
        }
    }
}