.main-heading {
  font-family: "Allegreya";
  margin-top: 2rem;
  text-align: center;
  color: #01471B;
  // font-size: 4rem;
  // font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  text-decoration: underline;
}

.grid-heading {
  margin-top: 2.2rem;
  text-align: center;
  color: #026125;
  font-size: 1.7rem;
  font-weight: 600;
  text-decoration: underline;

}

.sub-heading {
  margin-top: 1.7rem;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.main-text {
  width: 80%;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.3rem;
  ul {
    li {
      list-style: decimal;
    }
  }

  @media screen and (max-width:724px) {
    width: 95%;
    font-size: 1rem;
  }
}

.member_grid {

  display: grid;
  grid-template-columns: repeat(4,1fr);
  @media screen and (max-width:1225px) {
    grid-template-columns: repeat(3,1fr);
  }

  @media screen and (max-width:724px) {
    grid-template-columns: repeat(2,1fr);
  }

  // grid-auto-flow: column;
  // grid-template-columns: repeat(4,1fr);
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.member_grid2 {

  display: grid;
  grid-template-columns: repeat(3,1fr);
  @media screen and (max-width:1225px) {
    grid-template-columns: repeat(3,1fr);
  }

  @media screen and (max-width:724px) {
    grid-template-columns: repeat(2,1fr);
  }

  margin-top: 3rem;
  margin-bottom: 2rem;
}

.member {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__image {
      width: 170px;
      height: 170px;
      border-radius: 100%;
      @media screen and (max-width:724px) {
        width: 140px;
        height: 140px;
      }
  }
  &__name {
      margin-top: 1rem;
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 1px;
      @media screen and (max-width:724px) {
        font-size: 1.1rem;
      }

  }
  &__location {
      margin-top: 0.1rem;
      font-size: 1rem;
  }
}

@media only screen and (max-width: 990px) {
  .comHeads {
    flex-direction: column;
  }
}
