.image-container {
 &:hover {
   .hover-text {
     display: block;
     background: #000;
     color: white;
     text-align: center;
     width: 440px;
     
   }
 }
}

.hover-text {
  display: none;
}
