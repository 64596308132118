.LandAndBuilding {
  h1 {
    text-align: center;
    text-decoration: underline;
    color: #01471B;
  }
}

.pub{
  margin: 2rem 2rem;
  background-color: #badfce;
  width:85%;
  height:90%;
  border:3px solid #01471B;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;

  img{
    width: 65%;
    display: flex;
    margin: auto;
    border-radius: 10%;
  }
  h3{
    color:#01471B;
    text-align: center;
  }
//   @media screen and (max-width:927px) {
//     width:300px;
//     height:250px;
// }
  @media screen and (max-width:730px) {
    width:60%;
    padding: 2%;
    img{
      padding: 5%;
      border-radius: 10%;
      width: 40%;
    }
}
}


.publication-grid {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-template-columns: repeat(4,1fr);
  @media screen and (max-width:927px) {
      grid-template-columns: repeat(2,1fr);
  }
  @media screen and (max-width:730px) {
      display: flex;
      flex-direction: column;
  }
}



// .pdfList {
//   color: #01471B;
//   font-size: 1.4rem;
// }


