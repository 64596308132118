.Footer {
  background-color: #01471b;
  color: white;
  font-family: "Calibri";

  ul {
    list-style: none;
    padding-left: 0;
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:hover {
    color: rgb(214, 213, 213);
  }

  width: 100vw;
  padding: 0.7rem 0;

  .footer-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logosContainer {
      white-space: nowrap;
      #Logo {
        height: 5.5rem;
        cursor: pointer;
      }

      #Logo3 {
        height: 4.7rem;
        background-color: white;
        border-radius: 50%;
        margin: 0 0.5rem;
        cursor: pointer;
      }

      #Logo2 {
        height: 4.7rem;
        background-color: white;
        border-radius: 50%;
        margin: 0 0.5rem;
        cursor: pointer;
      }
    }

    #clgname {
      color: white;
      font-family: "Alegreya", serif;
      font-style: normal;
      border-bottom: 0.1rem solid white;
      width: fit-content;
      margin: 0 1rem;
      font-weight: bold;
    }
  }

  .mainContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 0;

    .mainContainerDiv {
      display: flex;
      width: 100%;
    }

    h4 {
      border-bottom: 0.1rem solid white;
      width: fit-content;
    }

    ul {
      white-space: nowrap;
      font-size: 1.2rem;

      li {
        font-size: 1rem;
      }
    }

    .mapContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 30%;

      p {
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
      }

      .Map {
        margin-top: 1.2rem;
        width: 70%;
      }
    }

    .vl {
      width: 0.2%;
    }

    .sections {
      width: 69.8%;
      display: flex;
      justify-content: flex-start;

      .Zcontainer {
        display: flex;
        flex-direction: row;
        width: 60%;

        .Zcontainerinner{
          display: flex;
          // width: 100%;
        }

        .section {
          width: 50%;
          line-height: 18px;
        }
      }

      .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        width: 40%;
        height: fit-content;

        ul {
          li {
            padding: 0.3rem;
          }
        }

        .form {
          form {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
              border: 0.2rem solid white;
              background-color: transparent;
              border-radius: 2rem;
              margin: auto;
            }

            .formInput {
              color: #01471b;
              text-align: center;
              background-color: white !important;
              margin: 0.6rem;
              border-radius: 2rem;
              width: 100%;
              height: 2rem;
              border: none;
            }

            .dropd {
              width: 106%;
            }

            #interested {
              width: 50%;
            }

            #role {
              width: 50%;
            }

            #submit {
              border-radius: 2rem !important;
              width: 10rem;
              font-size: 1.5rem;
              padding: 0rem 0rem;
              margin: auto;
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}

i {
  padding-right: 1rem;
}

#footervl {
  background-color: white;
  margin: 0rem 1rem;
}

footer {
  padding: 0.2rem;
  background-color: rgb(20, 20, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 1rem;
  font-family: "Calibri";
  img {
    height: 2rem;
  }

  p {
    margin-bottom: 0 !important;
    padding: 0rem 0.3rem;
    margin-top: 0.1rem;
  }
}

@media only screen and (min-width: 1368px) {
  .Zcontainer {
    .section{
      width: 30% !important;
    }

    .Zcontainerinner{
      flex-direction: row;
      width: 70%;

      .section{
        align-items: center !important;
        width: 50% !important;
        padding: 0.5rem 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1368px) {
  .Zcontainer {
    .section{
      width: 60% !important;
    }

    .Zcontainerinner{
      flex-direction: row;

      .section{
        align-items: center !important;
        padding: 0.5rem 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1180px) {

  #follow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem 0;
  }
  .Zcontainer {
    .section{
      width: 60% !important;
    }

    align-items: center !important;

    flex-direction: column !important;

    .Zcontainerinner{
      flex-direction: row;

      .section{
        align-items: flex-start !important;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .footer-main {
    flex-direction: column;

    #clgname {
      text-align: center;
      padding: 1rem 0;
    }

    margin: 1rem 0;
  }

  .mainContainerDiv {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mapContainer {
      width: 80% !important;
    }

    .vl {
      display: none;
    }

    .sections {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100% !important;

      .Zcontainer {
        width: 80% !important;
        flex-direction: column !important;
        align-items: center !important;
        margin: 1rem 0;

        .Zcontainerinner{
          flex-direction: row;
          margin: 1rem 0;

          .section{
            padding: 0.5rem;
          }
        }
      }

      .section {
        width: 80% !important;
        flex-direction: column;
        justify-content: center;
        .form {
          h4 {
            text-align: center;
          }
        }
      }
    }
  }
}

//Validations
.PhoneInputCountry {
  display: none !important;
}

.PhoneInputInput {
  text-align: center;
  background: transparent;
  border: none;
}

.invalid {
  color: red !important;
}
