.group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;

  img {
    width: 3rem;
    margin: 0rem 1rem;
  }
}

.groups {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 1rem;
}

.quotes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0fff8 ;
  margin: 1.5rem 0rem;
  color: #01471B;
  ;
  font-style: normal;
  font-weight: normal;
  padding: 1.5rem 0rem;

  h3 {
    width: 95%;
    margin: auto;
    text-align: center;
    font-weight: 600;
    padding-bottom: 1rem;
  }

  p{
    font-size: 1.5rem;
    width: 90%;
    line-height: 1.4;
    text-align: center;
  }
}
