.acontent {
  display: flex;
  width: 90%;
  padding: 1em 0;
  overflow: hidden;
  margin: 0 auto;
}
.acontent * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

//Marqueue

.acontent {
  .marquee * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
  .marquee {
    width: 100%;
    // background: #f0fff8 ;
    background: #f0fff8 ;
    float: left;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    height: 45px;
    position: relative;
    cursor: pointer;
  }

  .marquee-sibling {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    // background: #01471B;
    background: #01471B;
    width: 20%;
    height: 3rem;
    font-size: 1.1rem;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    float: left;
    left: 0;
    z-index: 0;
  }

  .marquee li {
    display: inline-block;
      line-height: 2.6rem;
    color: #000000;
    white-space: nowrap;
  }
}
