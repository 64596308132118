.TopSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    text-decoration: none;
  }
}

.reverse{
  .hl{
    background: linear-gradient(-90deg, rgba(90, 172, 120, 0.61) 0%, rgba(155, 207, 176, 0) 50.52%, rgba(254, 255, 254, 0.61) 70%);
  }
  
  .prinImg{
    left: initial;
    right: 8%;
  }

  .message{
    flex-direction: row-reverse;

    .msgContent{
      padding-left: 0;
      padding-right: 3rem;
      p{
        text-align: right;
      }
    }
  }
}

.prinImg {
  position: absolute;
  z-index: 1;
  height: 12rem;
  left: 8%;
  margin-top: 3rem;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}

.mobPrinImg {
  display: none;
}

.hl {
  // margin-top: 7rem;
  // margin-left: 18rem;
  height: 3rem;
  width: 75%;
  margin: auto;
  background: linear-gradient(
    90deg,
    rgba(90, 172, 120, 0.61) 0%,
    rgba(155, 207, 176, 0) 50.52%,
    rgba(254, 255, 254, 0.61) 70%
  );
}

.pvl {
  width: 3rem;
  background: linear-gradient(
    180deg,
    rgba(90, 172, 120, 0.61) 0%,
    rgba(155, 207, 176, 0) 50.52%,
    rgba(254, 255, 254, 0.61) 70%
  );
}

.message {
  width: 75%;
  margin: auto;
  display: flex;

  text-align: justify;

  p {
    font-size: 1.3rem;
  }
}

.msgContent {
  width: 90%;
  padding-left: 3rem;
  padding-top: 3rem;
  p {
    text-align: justify;
  }
}

.designation {
  color: black;
  text-align: right;
  width: max-content;
  display: contents;
  align-self: flex-end;
}

.PrincipalMsg {
  width: 100vw;
  margin: 2rem 0;

  .prinImg {
    display: inline;
    align-self: flex-start;
    // left: 7%;
  }
}

@media only screen and (max-width: 990px) {
  .pvl {
    display: none;
  }

  .PrincipalMsg {
    .prinImg {
      display: none;
    }
  }
  .message {
    width: 100%;
  }
  .mobPrinImg {
    display: block;
    height: 12rem;
    margin: 2rem 0;
    width: 12rem;
    height: 12rem;
    border-radius: 55rem;
  }

  .msgContent {
    padding: 0;
    margin: auto;

    p {
      margin: 1rem auto !important;
    }
  }
}
