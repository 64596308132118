#UCBSA-contact{
    height: 50%;
    background-color: #F6FFEF;
    padding: 0.5rem;
    h1,h2{
        color: #299702;
    }
    h1{
        text-align: center;
    }
    ol{
        text-align: center;
        list-style: none;
        li{
            font-family: "Callibri";
            font-size: 1.3rem;
            padding: 1rem;
        }
        margin:rem 1rem;
    }
    .contact-img{
       text-align: center;
    }
}
