*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

textarea:focus, input:focus{
    outline: none;
}

html {
    overflow-y: scroll;
    overflow-x: hidden;
}

#threed{
    display: none;
}

.close{
    background-color: transparent;
    color: black;
    border: none;
    font-size: 2rem;
}

.modal-body{
    position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

.and{
    font-family: "Poppins", sans-serif;
    color: inherit;
    font-size: inherit;
}


  
  

