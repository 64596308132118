@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300&display=swap");

.AboutUs {
  h1 {
    font-family: Alegreya;
    font-style: italic;
    // color: #326e46;
    color: #01471B;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.images {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    margin: 0 3rem;
    object-fit: cover;
    border: 0.2rem solid #01471B;
  }
}

.personCard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  p{
    margin: 0;
  }

  .name{
    white-space: nowrap;
  }

  .designation{
    width: 10%;

    span{
      display: inherit;
    }
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  p {
    width: 100%;
    padding: 2rem;
    // color: #3b7952;
    color: #01471B;
    ;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    text-align: justify;
  }
}

@media only screen and (max-width: "990px"){
  .content{
    p{
      font-size: 1.2rem;
    }
  }

  .images{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .personCard{
      width: 100%;
      margin: 1rem 0;
    }
  }
}
