.slick-arrow {
    z-index: 1;
    color: black;
}

.slick-prev {
    left: 1% !important;
    z-index: 1;
}

.slick-next {
    right: 1% !important;
    z-index: 1;
}

.imageCont {
    width: 100%;
}

.an {
    display: flex;
    flex-direction: column;
    background-color: #fffeec9a;
    padding: 1rem 0;
    .an-main {
        width: 90%;
        margin: 0.5rem auto;
        display: flex;
        .an-left {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .vector {
                margin: 2rem 0;
            }
            .an-buttons {
                display: flex;
                .an-button {
                    border: none;
                    margin: 0.5rem 1rem;
                    padding: 0.5rem 1rem;
                    width: fit-content;
                    background-color: #badfce;
                    a {
                        text-decoration: none;
                        color: black;
                    }
                }
            }
            // Button Code 
            .button-74 {
              background-color: #badfce;
              border: 1px solid #000000;
              // border-radius: 20px;
              font-family: "Calibri";
              box-shadow: #422800 3px 3px 0 0;
              color: #422800;
              cursor: pointer;
              display: inline-block;
              font-weight: 600;
              font-size: 1rem;
              padding: 0 28px;
              line-height: 28px;
              text-align: center;
              text-decoration: none;
              user-select: none;
              -webkit-user-select: none;
              touch-action: manipulation;
          }
          .button-74:hover {
              background-color: #fff;
          }
          .button-74:active {
              box-shadow: #422800 2px 2px 0 0;
              transform: translate(2px, 2px);
          }
          @media (min-width: 768px) {
              .button-74 {
                  min-width: 120px;
                  padding: 0 18px;
              }
          }
      
          @media (max-width: 676px) {
              .button-74 {
                  min-width: 70px;
                  padding: 0 10px;
                  font-size: 1.2rem;
                  line-height: 25px;
              }
          }
      
          @media (max-width: 400px) {
              .button-74 {
                  min-width: 50px;
                  line-height: 23px;
                  padding: 0 5px;
                  font-size: 0.7rem;
              }
      
              tr{
                  font-size: 1rem;
              }
          }
      
          @media (max-width: 300px) {
              .button-74 {
                  min-width: 30px;
                  padding: 0 1px;
                  font-size: 0.8rem;
              }
      
              tr{
                  font-size: 0.8rem;
              }
          }


            // End
        }
        .an-right {
            width: 50%;
            background-color: #fffff0;
            .an-headings {
                margin: 1.7rem;
            }
            .an-box {
                width: 90%;
                height: 29%;
                background-color: #badfce;
                padding: 1rem;
                p {
                    font-size: 1rem;
                }
            }
        }
    }
    .an-bottom {
        display: flex;
        width: 90%;
        margin-left: 5%;
        .vector2 {
            display: none;
        }
        .an-right {
            .an-headings {
                height: 30%;
                margin: 0.5rem;
            }
        }
        .an-image {
            width: 14%;
            height: 12rem;
            border-radius: 1rem 1rem 0 1rem;
        }
        .triangle {
            height: 6.4rem;
        }
        .an-bottom-content {
            display: flex;
            margin: 1rem 0;
            height: 51%;
            width: 100%;
            .an-bottom-para {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 80%;
                img {
                    width: 2%;
                }
                padding: 1rem;
                background-color: #badfce;
                p {
                    font-size: 1rem;
                }
            }
            .triangle {
                border-left: 36px solid #badfce;
                border-top: 32px solid transparent;
                border-right: 75px solid transparent;
                border-bottom: 32px solid transparent;
            }
        }
    }
}