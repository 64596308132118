@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.maincont {
  display: flex;
  justify-content: center;
  align-items: center;
  ;
  font-style: normal;
  padding: 1rem;
}

.principal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;

  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    color: #000000;
  }

  img {
    width: 15rem;
    height: 15rem;
    border-radius: 55rem;
  }
}

.vl {
  height: 18rem;
  width: 0.1rem;
  // background-color: #347443;
  background-color: #01471B;
}

.pmcontent {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 0rem 2rem;
  p {
    font-size: 1.1rem;
    text-align: justify;
  }
  h1 {
    // color: #01471b;
    color: #01471B;
  }
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem;

  p {
    margin: 0rem 0rem;
  }
}
