.Campus {
  width: 100%;
  height: 35vw;
  object-fit: cover;
}

.__react_modal_image__header {
  position: absolute;
  top: 75vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  align-items: center;
  text-align: center;
}

._react_modal_image_header{
  bottom: initial !important;
  text-align: center;
  align-items: center;
  bottom: 10%;
  top: 75vh;

  ._react_modal_image_icon_menu{
    display: none;
  }
}

.image-list {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(440px,1fr));
  grid-gap: 5px;
  grid-auto-rows: 10px;
}

.image-list img {
  width: 440px;
  grid-row-end: span 3;
  

}

.img-fluid {
  object-fit: cover;
  height: 100%;
  z-index: 2;
}

.imageCont {
  margin-top: 2rem;
  width: 100%;
}

.main {
  width: 90%;
  margin: 0.5rem auto;
}

.image_hover{
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(1, 1, 1);
  color: white;
  margin-bottom: 5px;
  font-family: sans-serif;
  opacity: 0;
  display: flex;
 }


.image_hover:hover {
  position: relative;
  cursor: pointer;
  visibility: visible;
  opacity: 2; 
}
 

.announcements {
  
  width: fit-content;
  height: 25vw;
  position: relative;
  left: 60%;
  margin-top: -33%;
  margin-bottom: 8%;
  ;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .whitediv {
    padding: 1rem;
    background-color: white;
  }

  h3 {
    // color: #01471B;
    color: #8a0303;
    margin: auto;
    padding-bottom: 1rem;
    white-space: nowrap;
  }

  ul {
    li {
      font-size: 1.2rem;
      padding-bottom: 0.75rem;
      line-height: 1.25rem;
      list-style: disc;
    }

    width: 100%;
    height: 25vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .button-fees {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    button {
      border: none;
      width: 100%;
      // background-color: #01471B;
      background-color: #8a0303;
      color: white;
      padding: 0.5rem 1rem;
      font-size: 1.1rem;
    }
  }
}


.hover-desc {
  &:hover {
 &::after {
      content : attr(desc);

      background-color: rgba($color: #020202, $alpha: 0.3);
      color: white;
      display: flex;
      justify-content: center;
      align-items:center;
      padding-bottom: 2rem;
      height: 50%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
}
