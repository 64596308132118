@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@1,500&family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alegreya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@700&display=swap');

.Navbar{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  nav{
    button{
      width: 100%;
    }
  }
}

#logo{
  width: 10%;
  margin: 1.2rem 0.6rem;
  cursor: pointer;
}

#logo2{
  width: 7%;
  margin: 1.2rem 0.6rem;
  cursor: pointer;
}

#clgName {
  font-family: "Alegreya", serif;
  margin-bottom: 0;
  color: #01471B;
  text-align: center;
  font-size: 1.9rem;
  font-weight: 700;
}

#clgName2 {
  font-family: "Alegreya", serif;
  margin-bottom: 0;
  color: #01471B;
  text-align: center;
  font-style: italic;
}

.clgHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

nav {
  width: 100%;
  color: #01471B;
  border-top: 0.2rem solid #01471B;
  border-bottom: 0.2rem solid #01471B;
  margin: auto;
}

.nav-link {
  width: 100%;
  font-size: 1.2rem;
  color: #01471B !important;
  font-weight: 500;
  cursor: pointer;
  margin: 0 0.3rem;
  text-align: center;
}

.nav-link:hover {
  @media only screen and (min-width:990px) {
    text-decoration: underline;
   }
  }

ul {
  list-style: none;
}

.drop {
  a{
    text-decoration: none;
    color:#01471B !important
  }
  &:hover {
    .drop-down {
   
       display: block;
      
    }
  }
}

.drop-down {
  border: 1px solid gray;
  display: none;
  position: absolute;
  @media only screen and (max-width:990px) {
    position: unset;
  }
  z-index: 100;
  background: white;
  font-size: 1.1rem;
  padding: 0.7rem;
  border-radius: 10px;
  box-shadow: 1px 1px 1px;
  @media only screen and (min-width:990px) {
    text-align: left;
  }

  &-item {
    display: block;
    &:visited {
      color: black;
      text-decoration: none;
    }
    &:hover {

        text-decoration: underline;
    }
    &-2 {
      margin-top: 1rem;
    }
  }
  
}

.click-visible {
  .drop-down {
    @media only screen and (max-width:990px) {
     display: block;
    }
  }
}