.facilities {
  border-top: 0.2rem solid #01471B;
  border-bottom: 0.2rem solid #01471B;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0rem auto;
  margin-top: 0rem;
  margin-bottom: 1rem;

  img {
    width: 4rem;
    height: 4rem;
  }
}

.fLogo {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p{
    text-align: center;
    margin: 1rem;
    width: 115%;
  }
}

.row {
  justify-content: center;
  margin-bottom: 1rem;
}
