#courses {
    p {
        font-size: 1.3rem;
        margin: 1rem auto;
    }
}

.background {
    text-align: justify;
    margin: 2rem auto;
    width: 85%;
    h2 {
        text-decoration: none;
        text-align: left;
    }
}

.mc_item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: #245F39;
    // margin: 2rem;
    border: 0.8rem solid white;
    padding: 1rem;
    border-radius: 2rem;

    .mc_img{
        width: 60%;
        border-radius: 2rem;
    }
    
    .mc_para{
        width: 90%;
        color: white;
    }
}

.course-card {
    margin: 1rem 0;
    padding: 1.5rem;
    color: white;
    text-align: justify;
    span {
        color: white;
        font-family: 'Oleo Script', cursive;
        font-size: 4rem;
    }

    a{
        color: orange;
        font-family: Calibri;
        font-size: 1.1rem;
    }
}

.card1 {
    background-color: #01471B;
}
.card1:hover{
    border: 2px solid black;
    box-shadow: 5px 5px;
    border-radius: 20px;
}
.card2 {
    background-color: #245F39; 
}

.card2:hover{
    border: 2px solid black;
    box-shadow: 5px 5px;
    border-radius: 20px;
}

.card3 {
    background-color: #519259;
}

.card3:hover{
    border: 2px solid black;
    box-shadow: 5px 5px;
    border-radius: 20px;
}


@media only screen and (min-width:576px) and (max-width:768px){
    #courses {
        p {
            font-size: 0.8rem;
            margin: 0.5rem auto;
        }
    }
    .course-card {
        margin: 1rem 0;
        padding: 1.2rem;
        span {
            font-size: 2rem;
        }
        h3 {
            line-height: 30px;
            font-size: 1.3rem;
        }
        p {
            margin-top: 0.4rem;
        }
        a{
            font-size: 0.8rem;
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px){
    #courses {
        p {
            font-size: 1rem;
            margin: 0.5rem auto;
        }
    }
    .course-card {
        margin: 0.8rem 0;
        padding: 1.2rem;
        span {
            font-size: 2.5rem;
        }
        h3 {
            line-height: 30px;
            font-size: 1.4rem;
        }
        a{
            font-size: 1rem;
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1080px){
    #courses {
        p {
            font-size: 1.1rem;
            margin: 0.8rem auto;
        }
    }
    .course-card {
        margin: 0.8rem 0;
        padding: 1.2rem;
        span {
            font-size: 2.8rem;
        }
        h3 {
            line-height: 30px;
            font-size: 1.4rem;
        }
        a{
            font-size: 1rem;
        }
    }
}