.nav-link {
    white-space: nowrap;
}

.numbers div{
    white-space: nowrap;
}

@media only screen and (max-width: 1480px) {
    .nav-link{
        font-size: 1.3rem !important;
        margin: 0 0.5rem !important ;
    }
}

@media only screen and (max-width: 1340px) {
    .nav-link{
        font-size: 1.1rem !important;
        margin: 0 0.5rem !important ;
    }
}

@media only screen and (max-width: 1080px) {
    .nav-link{
        margin: 0 0.3rem !important ;
    }
}

@media only screen and (max-width: 1040px) {
    .nav-link{
        margin: 0 0.1rem !important ;
    }
}

//Mobile & Tablet
@media only screen and (max-width: 990px) {

    .nav-link{
        margin: 0;
    }

    #logo{
        width: 40%;
        cursor: pointer;
    }
    
    .maincont{
        flex-direction: column;
        .vl{
            height: 0.1rem;
            width: 60vw;
            margin: 1rem;
        }

        padding: 2rem;
    }

    .an{
        padding: 0;
        .an-main{
            flex-direction: column;
        }

        .an-left{
            width: 100% !important;

            .vector{
                display: none;
            }

            .vector2{
                display: block;
            }

            .an-button{
                font-size: 1rem !important;
                margin: 0.5rem !important;
            }
        }

        .an-right{
            width: 100% !important;
            text-align: center;
            margin: 1rem auto;
            .an-box{
                width: 100% !important;
                margin: 1rem auto;
            }
        }

        .an-bottom{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1rem auto;

            .an-image{
                width: 90% !important;
            }

            .an-bottom-content{
                width: 100% !important;
                .triangle{
                    display: none;
                }
            }
        }
    }

    .news{
        text-align: center;
        padding: 0.5rem;
    }

    .fLogo{
        width: 10rem;
        padding: 1rem;
        p{
            // width: 90%;
            text-align: center;
        }

        img{
            width: 4.5rem;
            height: 4.5rem;
        }
    }

    .top{
        flex-direction: column;
        .cont1{
            flex-direction: column;
            width: 100% !important;
            padding: 1rem;
        }
        .cont2{
            flex-direction: column;
            width: 100%;
            padding: 1rem;
            .info{
                width: 100%;
                padding: 1rem;
            }
        }
    }

    .bottom{
        flex-direction: column-reverse;
        width: 100% !important;
        .cont1{
            text-align: center;
            flex-direction: column;
            width: 100% !important;
            padding: 1rem;
        }
        .cont2{
            flex-direction: column;
            width: 100%;
            padding: 1rem;
            .info{
                width: 100%;
                padding: 1rem;
            }
        }
    }

    .mapContainer{
        ul{
            li{
                text-align: center;
            }
        }
    }

    .quotes{
        width: 90%;
        margin: 2rem auto;
        .groups{
            padding: 1rem 0;
            flex-direction: column;
            align-items: flex-start;

            .group{
                padding: 1rem 0;
            }
        }
    }

    .personCard .designation {
        display: block;
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .numbers{
        .groups{
            flex-direction: column;
            align-items: flex-start;
            .group{
                justify-content: center;
                margin: 0 0;
            }
        }
    }

    .AboutUs{
        .content{
            flex-direction: column;
            p{
                width: 100%;
            }
        }
    }

    .pmcontent{
        width: 100% !important;
        text-align: center;
    }

    #header{
        flex-direction: column;
    }

    .principal{
        padding: 0;
    }
    
    #courses{
        ol{
            padding-left: 0.8rem !important;
            
            .coursesBrief{
                .courseBrief{
                    margin: inherit;
                }
            }
        }

        .main-heading{
            margin: 0 0.5rem;
        }
    }
}




