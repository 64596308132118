.aboutHead {
    font-family: "Callibri";
    color: #01471B;
    text-align: justify;
    width: 90%;
    margin: auto;
    h1 {
      font-family:"Allegreya";
      margin: 2rem auto;
      text-decoration: underline;
      text-align: center;
    }
  }