#UCBSA-contact{
    height: 50%;
    background-color: #F6FFEF;
    padding: 0.5rem;
    h1,h3{
        color: #299702;
    }
    h1{
        text-align: center;
    }
    h5{
        color: #01471B;
        padding: 0.2rem;
    }
    ol{
        text-align: center;
        list-style: none;
        li{
            padding: 1rem;
        }
        margin:rem 1rem;
    }
    .contact-img{
       text-align: center;
    }
}


// .contact__container { 
//     background-color: #F6FFEF;
//     padding: 2rem;
//     &__heading {
//         text-align: center;
//         font-size: 2.3rem !important;
//         color: #299702;
//     }
//     &-flex {
//         display: flex;
//         padding: 0 6rem;
//         justify-content: space-between;
//         align-items: center;
//         position: relative;
//         top: -45px;
//         @media screen and (max-width:900px) {
//             flex-direction: column;
//             padding-top: 4rem;
//         }
//     }

// }

// .contact__item {
//     height: 400px;
//     width: 400px;
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     align-items: center;
    
//     &-icon {
//         padding: 2rem;
//         background: white;
//         width: fit-content;
//         border-radius: 50%;
//         box-shadow: 1px 2px 5px #aaaaaa;
//         position: relative;
//         top: 2rem;
//         color: #299702;



//     }
//     &-text {
//         background: white;
//         height: 60%;
//         border-radius: 1rem;
//         padding: 2rem;
//         width: 90%;
//         box-shadow: 1px 2px 5px #aaaaaa;
//         text-align: center;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         &-heading {
//             color: #299702;
//             font-size: 1.4rem;
//             font-weight: 600;
//         }
//         &-desc {
//             margin-top: 0.7rem;
//             font-size: 1.1rem;
//             color: #01471B;

//         }

//     }
// }