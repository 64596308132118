.heading{
    margin: 2rem auto;
    text-decoration: underline;
    text-align: center;
    color: #01471B;
}

.not-found{
    width: 40%;
}

.main{
    .image{
        display: flex;
        justify-content: center;
    }
}

.cpd{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    font-family: Calibre;
    font-size: 1.1rem;
    .left{
        width: 80%;
        img{
            width: 100%;
        }
    }
    .right{
        padding: 1rem;
    }
}

@media only screen and (max-width: 940px) {
    .cpd{
        flex-direction: column;
        .left{
            width: 100%;
        }
    }
}