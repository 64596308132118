.NEWS{
  margin: 2rem 0;
  h1 {
    color: #01471B;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: 0;
    text-decoration: none;
  }
}

.news {
  width: 90vw;
  // height: 80vh;
  margin: auto;
  // padding: 2rem;
  border: 0.1rem solid #01471B;
  margin-top: 2rem;
  padding: 2rem;
}

.top {
  display: flex;
  align-items: center;
  ;
  width: 100%;
  .cont1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 3rem;
    color: #01471B;
  }

  .carousel-root{
    width: 70%;
  }
  
  .cont2 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    background: rgba(229, 229, 229, 0.09);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .info {
      width: 70%;
      display: flex;
      flex-direction: column;
    }

    .date {
      width: fit-content;
      padding: 1rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 0 2rem;

      #date {
        font-size: 2rem;
      }
      #month {
        font-size: 1.1rem;
      }
    }

    border-bottom: 0.2rem solid #01471B;
  }
}

.bottom {
  display: flex;
  ;
  align-items: center;
  margin-top: 2rem;
  width: 100%;

  #big {
    color: white;
  }
  .cont1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 3rem;
    color: #01471B;
    text-align: end;
  }

  .carousel-root{
    width: 70%;
  }

  .cont2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    background: #01471B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .info {
      width: 70%;
      display: flex;
      flex-direction: column;
      color: white;
    }

    .date {
      width: fit-content;
      padding: 1rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      #date {
        font-size: 2rem;
      }
      #month {
        font-size: 1.1rem;
      }
    }

    border-bottom: 0.2rem solid #01471B;
  }
}

.carousel-root {
  width: 100%;
}

.newsLink{
  text-decoration: none;
}

.eventLink{
  text-decoration: none;
  color: white !important;
}
